import { makeStyles } from 'tss-react/mui'
import React from 'react'
import { usePrevious } from '../../../shared-components/utils/hooks'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { Box } from '@mui/material'
import Row from '../../Row'
import { indexMobileBreakpointOld } from '../../../types/types'
import Image from 'next/image'

const animDurationMs = 300
const opacityAnimationDuration = 200
const translateInPercentage = 15

const sharedStyle = {
  transition: `opacity ${opacityAnimationDuration / 1000}s ease-in-out, transform ${animDurationMs / 1000}s ease-in-out`,
}

const useStyles = makeStyles()((theme) => ({
  heightAnimate: {
    overflow: 'visible !important',
  },
  root: {
    position: 'relative',
  },
  enter: {
    position: 'absolute',
    transform: `translateX(-${translateInPercentage}%)`,
    opacity: 0,
    width: '100% !important',
    height: '100% !important',
    ...sharedStyle,
  },
  enterRight: {
    position: 'absolute',
    transform: `translateX(${translateInPercentage}%)`,
    opacity: 0,
    width: '100% !important',
    height: '100% !important',
    ...sharedStyle,
  },
  enterActive: {
    position: 'absolute',
    transform: 'translateX(0)',
    opacity: 1,
    ...sharedStyle,
  },
  enterDone: {
    position: 'relative',
    transform: 'translateX(0)',
    opacity: 1,
    ...sharedStyle,
  },
  exit: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100% !important',
    height: '100% !important',
    opacity: 1,
    transform: 'translateX(0)',
    ...sharedStyle,
  },
  exitActive: {
    position: 'absolute',
    transform: `translateX(${translateInPercentage}%)`,
    opacity: 0,
    ...sharedStyle,
  },
  exitActiveRight: {
    position: 'absolute',
    transform: `translateX(-${translateInPercentage}%)`,
    opacity: 0,
    ...sharedStyle,
  },
  exitDone: {
    position: 'absolute',
    transform: `translateX(${translateInPercentage}%)`,
    opacity: 0,
    ...sharedStyle,
  },
  exitDoneRight: {
    position: 'absolute',
    transform: `translateX(-${translateInPercentage}%)`,
    opacity: 0,
    ...sharedStyle,
  },
}))

interface Props {
  activeStep: number
}

const visuals = {
  0: '/images/home/visual_home_step_1.webp',
  1: '/images/home/visual_home_step_2.webp',
  2: '/images/home/visual_home_step_3.webp',
  3: '/images/home/visual_home_step_4.webp',
  4: '/images/home/visual_home_step_5.webp',
}

const HowItWorksVisualAnimator = ({ activeStep }: Props) => {
  const { classes, cx } = useStyles()
  const prevStep = usePrevious(activeStep)

  return (
    <Row
      sx={(theme) => ({
        flexGrow: 1,
        position: 'relative',
        width: 1,
        height: 520,
        flexBasis: 350,
        alignItems: 'center',
        [theme.breakpoints.down(indexMobileBreakpointOld)]: {
          height: 400,
        },
      })}
    >
      <TransitionGroup
        component={null}
        childFactory={(child) => {
          const goingRight = prevStep < activeStep
          const leftClasses = {
            enterActive: classes.enterActive,
            enterDone: classes.enterDone,
            enter: classes.enter,
            exit: classes.exit,
            exitActive: classes.exitActive,
            exitDone: classes.exitDone,
          }
          const rightClasses = {
            enterActive: classes.enterActive,
            enterDone: classes.enterDone,
            enter: classes.enterRight,
            exit: classes.exit,
            exitActive: classes.exitActiveRight,
            exitDone: classes.exitDoneRight,
          }

          return React.cloneElement(child, {
            // @ts-expect-error
            classNames: goingRight ? rightClasses : leftClasses,
            key: child.key,
            timeout: animDurationMs,
          })
        }}
      >
        <CSSTransition key={activeStep} timeout={animDurationMs}>
          <Box
            sx={{
              width: 1,
              height: '100%',
              position: 'relative',
              overflow: 'hidden',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Image
              src={visuals[activeStep]}
              alt={''}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                transition: `opacity ${opacityAnimationDuration}ms ease-in-out`,
              }}
              width={1091}
              height={1111}
              quality={100}
              priority
            />
          </Box>
        </CSSTransition>
      </TransitionGroup>
    </Row>
  )
}

export default HowItWorksVisualAnimator
