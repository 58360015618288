import React from 'react'
import { Theme, useTheme } from '@mui/material/styles'
import { makeStyles } from 'tss-react/mui'
import Link from 'next/link'
import TextLogo from '../../svg/TextLogo'
import { Box, SxProps, Typography, useMediaQuery } from '@mui/material'
import Logo from 'svg/logo/IconBoxed'
import { Add, Search } from '@mui/icons-material'
import Row from '../Row'
import { useTranslation } from 'react-i18next'

const smallBreakpoint = 500

const useHeaderStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    // maxWidth: '1120px',
    gap: theme.spacing(4),
    width: '100%',
    alignItems: 'center',
    marginBottom: theme.spacing(8.5),
    padding: '32px 120px',
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(7),
      gap: theme.spacing(2),
    },
    '@media not all and (min-width:500px)': {
      justifyContent: 'center',
    },
    [theme.breakpoints.down(smallBreakpoint)]: {
      padding: '32px 16px',
    },
  },
  link: {
    color: theme.palette.primary.main,
  },
  download: {
    color: theme.palette.primary.main,
  },
  currentPage: {
    marginRight: 'auto',
  },
  tab: {
    color: theme.palette.text.primary,
    position: 'relative',
  },
  smallerOnMobile: {
    '@media not all and (min-width:500px)': {
      fontSize: '1rem',
    },
  },
  wordLogo: {
    color: theme.palette.grey[800],
    width: '56.54px',
    height: '14px',
    [theme.breakpoints.down(smallBreakpoint)]: {
      display: 'none',
    },
  },
  selectedLine: {
    position: 'absolute',
    width: '100%',
    height: '1px',
    background: theme.palette.grey[900],
    bottom: '-4px',
  },
  hideOnMobile: {
    '@media not all and (min-width:420px)': {
      display: 'none',
    },
  },
  flex: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '10.5px',
    marginRight: 'auto',
  },
  iconLogo: {
    width: 13.48,
    height: 14,
    color: theme.palette.grey[800],
    [theme.breakpoints.down(smallBreakpoint)]: {
      width: 18,
      height: 18,
    },
  },
  signIn: {
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
  createEvent: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    color: theme.palette.secondary[800],
    cursor: 'pointer',
  },
  addIcon: {
    width: 20,
    height: 20,
  },
  forYou: {
    display: 'flex',
    alignItems: 'center',
    gap: 6,
  },
  searchIcon: {
    width: 16,
    height: 16,
  },
  smallIcon: {
    width: 18,
    height: 18,
  },
}))

interface Props {
  sx?: SxProps<Theme>
  isHome?: boolean
  isForYou?: boolean
}

export const HomeHeader = ({
  sx = [],
  isHome = false,
  isForYou = false,
}: Props) => {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down(500))
  const { classes, cx } = useHeaderStyles()
  const { t } = useTranslation('common')

  return (
    <Box className={classes.flex}>
      <Box className={classes.root} sx={sx}>
        <Link
          href='/'
          passHref
          prefetch={false}
          className={classes.logoContainer}
        >
          <Logo className={cx(classes.iconLogo)} />
          {!isHome && (
            <TextLogo
              height={14}
              width={56}
              className={cx(classes.currentPage, classes.wordLogo)}
            />
          )}
        </Link>

        <Typography
          className={cx(classes.tab, classes.smallerOnMobile)}
          variant={isSmall ? 'body2' : 'body1'}
          sx={{
            color: 'secondary.800',
          }}
        >
          <Link href='/create' passHref prefetch={false}>
            <Row
              sx={{
                alignItems: 'center',
                gap: 0.75,
                color: 'secondary.800',
              }}
            >
              <Add
                sx={(theme) => ({
                  width: 16,
                  height: 16,
                  color: 'secondary.800',
                  [theme.breakpoints.down(smallBreakpoint)]: {
                    display: 'none',
                  },
                })}
              />
              {t('createEvent')}
            </Row>
          </Link>
        </Typography>

        <Typography
          className={cx(classes.tab, classes.smallerOnMobile)}
          variant={isSmall ? 'body2' : 'body1'}
        >
          {isForYou && <div className={classes.selectedLine} />}
          <Link href='/foryou' passHref prefetch={false}>
            <div className={classes.forYou}>
              <Search
                sx={{
                  [theme.breakpoints.down(smallBreakpoint)]: {
                    display: 'none',
                  },
                }}
                className={classes.searchIcon}
              />
              Hot events
            </div>
          </Link>
        </Typography>

        <Typography
          className={cx(classes.tab, classes.smallerOnMobile, classes.signIn)}
          variant={isSmall ? 'body2' : 'body1'}
        >
          <Link href='/signIn'>{t('signIn')}</Link>
        </Typography>
      </Box>
    </Box>
  )
}

export default HomeHeader
